import { actionCreatorFactory } from 'typescript-fsa';
import { RemoveRolesRequest, RestoreRolesRequest } from './typings';
import { Dict } from '@ea/common';

const ActionFactory = actionCreatorFactory('ROLES');

export const RolesActions = {
	getRoles: ActionFactory.async<{}, Dict[], Error>('GET_ROLES'),
	batchRemove: ActionFactory.async<RemoveRolesRequest, {}, Error>('BATCH_REMOVE'),
	batchRestore: ActionFactory.async<RestoreRolesRequest, {}, Error>('BATCH_RESTORE'),
};
