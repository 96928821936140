import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { includes, isArray, mapValues } from 'lodash-es';
import {
	Dict,
	initialContentLoading,
	initialPaginatedContentLoading,
	PaginatedParametrizedContentLoading,
	ParametrizedContentLoading,
} from '@ea/common';
import { join } from '../../../../core/services/lists';
import { ReferencesActions } from '../../entities/references/actions';
import {
	removeRows,
	removeRowsSelection,
	reorderColumns,
	updateSelectionStatistics,
} from '../../../../core/services/grid';
import { RoleShort } from '../../details/role/typings';
import { FilterDefinition } from '../../../../service/typings/filters';
import { CompanyShort, FunctionShort, ProfileShort } from '../../../../service/typings/entites';
import { GridColumn, GridCommonSelection } from '../../../../service/typings/grid';
import { Row } from '../../../../service/typings/lists';
import { GetProfilesListResponse } from '../profiles/typings';
import { RolesActions } from '../../entities/roles/actions';
import { RolesListActions } from './actions';

interface Data {
	companies: Dict<CompanyShort>;
	functions: Dict<FunctionShort>;
	roles: Dict<RoleShort>;
	rows: Row[];
	changed: boolean;
}

export interface RolesListState {
	data: PaginatedParametrizedContentLoading<Data>;
	columns: ParametrizedContentLoading<GridColumn[]>;
	filters: ParametrizedContentLoading<FilterDefinition[]>;
	selection: GridCommonSelection;
	lastParams: Dict;
	scroll: number;
}

const initialData = () => ({
	companies: {},
	functions: {},
	roles: {},
	rows: [],
	changed: false,
});

const initialState: RolesListState = {
	data: initialPaginatedContentLoading(initialData(), {}),
	columns: initialContentLoading([], {}),
	filters: initialContentLoading([], {}),
	lastParams: {},
	selection: {
		rows: {},
		companies: {},
	},
	scroll: 0,
};

export const RolesListReducer = reducerWithInitialState(initialState)
	.case(RolesListActions.resetParams, (state) => ({
		...state,
		data: {
			...state.data,
			params: {},
		},
		lastParams: {},
	}))
	.case(RolesListActions.setChanged, (state) => ({
		...state,
		data: {
			...state.data,
			content: {
				...state.data.content,
				changed: true,
			},
			params: {},
		},
	}))
	.case(RolesListActions.getList.started, (state) => ({
		...state,
		data: {
			...state.data,
			loading: {
				type: 'full',
				status: 'loading',
			},
		},
	}))
	.case(RolesListActions.getList.done, (state, { result, params }) => ({
		...state,
		data: {
			content: join(initialData(), normalize(result)) as Data,
			loading: {
				type: 'full',
				status: 'loaded',
			},
			pagination: result.page,
			params,
		},
		scroll: 0,
	}))
	.case(RolesListActions.getList.failed, (state, { params, error }) => ({
		...state,
		data: {
			...state.data,
			content: initialData(),
			loading: {
				type: 'full',
				status: 'loaded',
			},
			params,
			error,
		},
	}))
	.case(RolesListActions.loadMore.started, (state) => ({
		...state,
		data: {
			...state.data,
			loading: {
				type: 'append',
				status: 'loading',
			},
		},
	}))
	.case(RolesListActions.loadMore.done, (state, { result }) => ({
		...state,
		data: {
			...state.data,
			content: join(state.data.content, normalize(result)) as Data,
			loading: {
				type: 'append',
				status: 'loaded',
			},
			pagination: result.page,
		},
	}))
	.case(RolesListActions.updateColumns, (state, columns) => ({
		...state,
		columns: {
			...state.columns,
			content: columns,
		},
	}))
	.case(RolesListActions.getColumns.started, (state) => ({
		...state,
		columns: {
			...state.columns,
			loading: {
				status: 'loading',
			},
		},
	}))
	.case(RolesListActions.getColumns.done, (state, { result, params }) => ({
		...state,
		columns: {
			...state.columns,
			content: result || [],
			loading: {
				status: 'loaded',
			},
			params,
		},
	}))
	.case(RolesListActions.reorderColumns.done, (state, { result }) => ({
		...state,
		columns: {
			...state.columns,
			content: reorderColumns(state.columns.content, result),
		},
	}))
	.case(RolesListActions.addColumn.done, (state, { result }) => ({
		...state,
		columns: {
			...state.columns,
			content: [...state.columns.content, ...result.map((el) => ({ ...el, editing: true }))],
		},
	}))
	.case(RolesListActions.showColumns.done, (state, { result }) => ({
		...state,
		columns: {
			...state.columns,
			content: [...state.columns.content.filter((a) => !result.find((b) => a.field === b.field)), ...result],
		},
	}))
	.case(RolesListActions.removeColumn.done, (state, { params }) => ({
		...state,
		columns: {
			...state.columns,
			content: state.columns.content.filter((el) => el.addColumnId !== params.addColumnId),
		},
		filters: {
			...state.filters,
			content: state.filters.content.filter((el) => el.addColumnId !== params.addColumnId),
		},
	}))
	.case(RolesListActions.hideColumn.done, (state, { params }) => {
		const column = state.columns.content.find((el) => el.id === params.id);

		if (column) {
			return {
				...state,
				filters: {
					...state.filters,
					content: state.filters.content
						.filter((filter) => !filter.custom || filter.field !== column.field)
						.map((filter) => ({ ...filter, hidden: filter.field === column.field ? true : filter.hidden })),
				},
			};
		}
		return state;
	})
	// TODO: подумать как малой кровью переписать экшены, чтобы не повторялся код
	.case(ReferencesActions.removeReference.done, (state, { result: { id, addColumnId } }) => {
		const column = (state.columns.content || []).find((el) => el.addColumnId === addColumnId);
		if (column && column.field) {
			const field = column.field as string;
			return {
				...state,
				data: {
					...state.data,
					content: {
						...state.data.content,
						roles: mapValues(state.data.content.roles, (value) => ({
							...value,
							[field]: value[field] && value[field].id === id ? null : value[field],
						})),
					},
				},
			};
		} else {
			return state;
		}
	})
	.case(RolesListActions.updateSelection, (state, data) => ({
		...state,
		selection: {
			...state.selection,
			...data,
		},
	}))
	.case(RolesListActions.getStatistics.done, (state, { result }) => ({
		...state,
		selection: updateSelectionStatistics(state.selection, result),
	}))
	.case(RolesListActions.getFilters.started, (state) => ({
		...state,
		filters: {
			...state.filters,
			loading: {
				status: 'loading',
			},
		},
	}))
	.case(RolesListActions.getFilters.done, (state, { result, params }) => ({
		...state,
		filters: {
			...state.filters,
			content: result,
			loading: {
				status: 'loaded',
			},
			params,
		},
	}))
	.case(RolesListActions.updateFilters.done, (state, { result }) => ({
		...state,
		filters: {
			...state.filters,
			// TODO: убрать проверку
			content: isArray(result) ? result : state.filters.content,
		},
	}))
	.case(RolesListActions.rememberScroll, (state, { scroll }) => ({
		...state,
		scroll,
	}))
	.case(RolesListActions.rememberParams, (state, lastParams) => ({
		...state,
		lastParams,
	}))
	.cases([RolesActions.batchRemove.done, RolesActions.batchRestore.done], (state, { params }) => ({
		...state,
		data: {
			...state.data,
			content: {
				...state.data.content,
				rows: removeRows(state.data.content.rows, (row) => includes(params, row.id)),
			},
		},
		selection: removeRowsSelection(state.selection, params),
	}));

function normalize(data: GetProfilesListResponse) {
	const result: Omit<Data, 'changed'> = {
		companies: {},
		functions: {},
		roles: {},
		rows: [],
	};

	const { companies } = data._embedded;

	(companies || []).forEach((company) => {
		const { functions, ...companyData } = company;

		if (companyData.id) {
			result.companies[companyData.id] = companyData;
			result.rows.push({
				type: 'companies',
				id: companyData.id,
			});

			(functions || []).forEach(({ roles, ...functionData }) => {
				result.functions[functionData.id] = { companyId: companyData.id, ...functionData };
				result.rows.push({
					type: 'functions',
					id: functionData.id,
					companyId: companyData.id,
				});

				(roles || []).forEach((role: ProfileShort) => {
					result.roles[role.id] = role;
					result.rows.push({
						type: 'roles',
						id: role.id,
						functionId: functionData.id,
						companyId: companyData.id,
					});
				});
			});
		}
	});

	return result;
}
