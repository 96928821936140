import { ButtonBase, withStyles } from '@material-ui/core';
import React from 'react';
import cn from 'classnames';
import { IButtonProps } from '../../../../core/interfaces/ui/props';

export const  Ripple = withStyles({
	root: {
		width: '100%',
		display: 'block',
	},
})(({ classes, className, children, onClick }: IButtonProps) => (
	<ButtonBase className={cn(classes.root, className)} onClick={onClick}>
		{children}
	</ButtonBase>
));
