import styled from 'astroturf';
import { useTranslation } from 'react-i18next';
import React, { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SimpleDropdown } from '../SimpleDropdown';
import { SelectMenu } from '../../../service/Select/SelectMenu';
import { SearchSection } from '../../selects/AdvancedSelect/SearchSection';
import { SelectMenuWrapper } from '../../../service/Select/SelectMenuWrapper';
import { BlockButton } from '../../buttons/BlockButton';
import { ReactComponent as ArrowIcon } from '../../../../images/icons/arrow.svg';
import { ListItem } from '../../../../service/typings/interface';
import { transformDataToSelectList } from '../../../../service/utils/transformDataToSelectList';
import { useModal } from '../../../../service/hooks/useModal';
import { CustomSelectItem } from './CustomSelectItem';

interface IProps {
	values?: ListItem[];
	companyId?: number;
	functionId?: number;
	className?: string;
	disabled?: boolean;
	placeholder?: string;
	active?: boolean;
	border?: boolean;
	disablePortal?: boolean;
	onChange?: (values: number[], items: ListItem[]) => void;
	onGetCompanies: () => Promise<any>;
	onGetFunctions: (companyId: number) => Promise<any>;
	onGetProfiles: (companyId: number, functionId: number, query?: string) => Promise<any>;
}

export const ProfileSelect: FC<IProps> = memo(
	({
		companyId,
		functionId,
		className,
		disabled,
		values,
		placeholder,
		active,
		border,
		onChange,
		disablePortal,
		onGetCompanies,
		onGetFunctions,
		onGetProfiles,
	}) => {
		const { t } = useTranslation();
		const [loading, setLoading] = useState(false);
		const [internalItems, setInternalItems] = useState<ListItem[]>([]);
		const [companyList, setCompanyList] = useState<ListItem[]>([]);
		const [functionList, setFunctionList] = useState<ListItem[]>([]);

		const [selectedProfiles, setSelectedProfiles] = useState<ListItem[]>([]);

		const [additionalCompanyId, setAdditionalCompanyId] = useState<string | number>();
		const [additionalFunctionId, setAdditionalFunctionId] = useState<string | number>();

		const [open, onOpen, onClose] = useModal();

		const container = useRef<HTMLDivElement>(null);
		useEffect(() => {
			values && values.length > 0 && setSelectedProfiles(values);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [values]);

		const checkedOnChange = useCallback((item: ListItem, checked: boolean) => {
			if (checked) {
				setSelectedProfiles((prevState) => [...prevState, item]);
			} else {
				setSelectedProfiles((prevState) =>
					prevState.filter((x) => (x.value ? x.value !== item.value : x.label !== item.label)),
				);
			}
		}, []);

		const handleOpenDropdown = useCallback(() => {
			setLoading(true);
			// TODO: проверить типизацию
			if ((additionalCompanyId || companyId) && (additionalFunctionId || functionId)) {
				onGetProfiles(
					(additionalCompanyId || companyId) as number,
					(additionalFunctionId || functionId) as number,
				)
					.then((data) => {
						setInternalItems(data.map((item: any) => ({ label: item.name, value: item.profileId })));
					})
					.finally(() => {
						setLoading(false);
						onOpen();
					});
			}
		}, [additionalCompanyId, companyId, additionalFunctionId, functionId, onGetProfiles, onOpen]);

		useEffect(() => {
			if (!open) {
				setInternalItems([]);
				setCompanyList([]);
				setFunctionList([]);
				values && values.length > 0 ? setSelectedProfiles(values) : setSelectedProfiles([]);
				setAdditionalCompanyId(undefined);
				setAdditionalFunctionId(undefined);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [open]);

		const onShowCompanies = useCallback(() => {
			setCompanyList([]);
			setFunctionList([]);
			setAdditionalCompanyId(undefined);
			setAdditionalFunctionId(undefined);
			onGetCompanies().then((data) => setCompanyList(transformDataToSelectList(data)));
		}, [onGetCompanies]);

		const onShowFunctions = useCallback(
			(id?: number | string) => () => {
				if (id) {
					setAdditionalCompanyId(id);
				}
			},
			[],
		);

		useEffect(() => {
			if (additionalCompanyId) {
				// TODO: проверить типизацию
				onGetFunctions((additionalCompanyId || companyId) as number).then((data: any) =>
					setFunctionList(transformDataToSelectList(data)),
				);
			}
		}, [additionalCompanyId, companyId, onGetFunctions]);

		const onShowProfiles = useCallback(
			(id?: number | string) => () => {
				if (id) {
					setAdditionalFunctionId(id);
					setLoading(true);
				}
			},
			[],
		);

		useEffect(() => {
			if (additionalFunctionId) {
				// TODO: проверить типизацию
				onGetProfiles(
					(additionalCompanyId || companyId) as number,
					(additionalFunctionId || functionId) as number,
				)
					.then((data: any[]) => {
						setInternalItems(data.map((item: any) => ({ label: item.name, value: item.profileId })));
						setCompanyList([]);
						setFunctionList([]);
					})
					.finally(() => {
						setLoading(false);
					});
			}
		}, [additionalFunctionId, functionId, additionalCompanyId, companyId, onGetProfiles]);

		const onBack = useCallback(() => {
			if (functionList.length > 0) {
				setFunctionList([]);
				setAdditionalFunctionId(undefined);
				setAdditionalCompanyId(undefined);
			} else if (companyList.length > 0) {
				setCompanyList([]);
				setAdditionalCompanyId(undefined);
			}
		}, [functionList, companyList]);

		const onSaveItems = useCallback(() => {
			onChange &&
				onChange(
					selectedProfiles.map(({ value }: any) => value),
					selectedProfiles,
				);
			onClose();
		}, [onChange, onClose, selectedProfiles]);

		const onSearchItems = useCallback(
			(query: string) => {
				onGetProfiles(
					(additionalCompanyId || companyId) as number,
					(additionalFunctionId || functionId) as number,
					query,
				).then((data: any) => {
					setInternalItems(data.map((item: any) => ({ label: item.name, value: item.profileId })));
					setFunctionList([]);
					setCompanyList([]);
				});
			},
			[onGetProfiles, additionalCompanyId, companyId, additionalFunctionId, functionId],
		);

		const dropdownLabel = useMemo(() => {
			if (selectedProfiles.length > 1) {
				return `${t('ui.selected')}: ${selectedProfiles.length}`;
			} else if (selectedProfiles.length === 1) {
				return selectedProfiles[0].label;
			}
		}, [t, selectedProfiles]);

		return (
			<Wrapper ref={container} className={className}>
				<StyledSimpleDropdown
					value={dropdownLabel}
					disabled={disabled}
					open={open}
					placeholder={placeholder || t('ui.selectPlaceholder')}
					onClick={handleOpenDropdown}
					loading={loading}
					active={active}
					border={border}
				/>
				<SelectMenu
					onClickOutside={onClose}
					anchor={container}
					open={open}
					limitHeight
					sameWidth={true}
					disablePortal={disablePortal}
					listStyle={{ zIndex: 10000 }}
				>
					<StyledSelectMenuWrapper>
						<SearchSection onSearch={onSearchItems} />

						<Lists>
							{companyList.length > 0 || functionList.length > 0 ? (
								<BackButton onClick={onBack}>
									<StyledBackIcon />
									{t('common.actions.back')}
								</BackButton>
							) : (
								<>
									<Items>
										{selectedProfiles.map((item) => (
											<CustomSelectItem
												key={item.value}
												label={item.label}
												value={item.value}
												onChange={checkedOnChange}
												checked={true}
											/>
										))}
									</Items>
									<OtherProfileButton onClick={onShowCompanies}>
										{t('common.words.otherProfile')}
									</OtherProfileButton>
								</>
							)}
							<List>
								{!additionalCompanyId &&
									companyList.map((item, index) => (
										<ListItemBlock onClick={onShowFunctions(item.value)} key={index}>
											{item.label}
										</ListItemBlock>
									))}

								{functionList.map((item, index) => (
									<ListItemBlock onClick={onShowProfiles(item.value)} key={index}>
										{item.label}
									</ListItemBlock>
								))}
							</List>
							<Items>
								{!(companyList.length > 0 || functionList.length > 0) &&
									internalItems
										.filter((item) => !selectedProfiles.some((el) => el.value === item.value))
										.map((item) => (
											<CustomSelectItem
												key={item.value}
												label={item.label}
												value={item.value}
												onChange={checkedOnChange}
												checked={false}
											/>
										))}
							</Items>
						</Lists>

						{selectedProfiles.length > 0 && (
							<ButtonWrap>
								<SaveButton color={'blue'} onClick={onSaveItems}>
									{t('common.actions.save')}
								</SaveButton>
							</ButtonWrap>
						)}
					</StyledSelectMenuWrapper>
				</SelectMenu>
			</Wrapper>
		);
	},
);

const StyledSelectMenuWrapper = styled(SelectMenuWrapper)`
	max-height: 400px;
	min-width: 192px;
	display: flex;
	flex-direction: column;
	padding-bottom: 15px;
`;

const Wrapper = styled.div`
	@import '../../../../styles/constants.scss';
	position: relative;
`;
const Items = styled.div`
	@import '../../../../styles/mixins.scss';
	@include thin-scrollbar(#d8d8d8);
	width: 100%;
`;
const ButtonWrap = styled.div`
	margin: 12px 20px 11px 20px;
`;

const SaveButton = styled(BlockButton)`
	width: 100%;
`;

const StyledBackIcon = styled(ArrowIcon)`
	margin-right: 12px;
	fill: #008ac6;
`;

const OtherProfileButton = styled.div`
	font-size: 12px;
	margin: 15px auto 15px 12px;
	color: #008ac6;
	cursor: pointer;
	position: sticky;
	bottom: 0;
	background: #ffffff;
	padding: 3px 0;
`;

const BackButton = styled.div`
	font-size: 12px;
	margin: 15px auto 15px 12px;
	color: #008ac6;
	cursor: pointer;
	display: flex;
	align-items: center;
`;

const List = styled.div`
	@import '../../../../styles/mixins.scss';
	@include thin-scrollbar(#d8d8d8);
	margin-left: 34px;
`;

const ListItemBlock = styled.div`
	@import '../../../../styles/constants.scss';
	font-size: 12px;
	cursor: pointer;
	padding: 4px;
	overflow: hidden;
	text-overflow: ellipsis;
	& + & {
		margin-top: 8px;
	}
	&:hover {
		background-color: rgba($color-gray, 0.5);
	}
`;

const Lists = styled.div`
	@import '../../../../styles/mixins.scss';
	@include thin-scrollbar(#d8d8d8);
	overflow: auto;
	position: relative;
`;

const StyledSimpleDropdown = styled(SimpleDropdown)`
	background: none;
`;
