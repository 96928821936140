import { actionCreatorFactory } from 'typescript-fsa';
import {
	GetRoleDetailsRequest,
	GetRoleDetailsResponse,
	UpdateRoleDetailsRequest,
	UpdateRoleDetailsResponse,
} from './typings';

const ActionFactory = actionCreatorFactory('ROLE_DETAIL');

export const RoleDetailsActions = {
	get: ActionFactory.async<GetRoleDetailsRequest, GetRoleDetailsResponse, Error>('GET'),
	update: ActionFactory.async<UpdateRoleDetailsRequest, UpdateRoleDetailsResponse, Error>('UPDATE'),
};
