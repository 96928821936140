import { forEach } from 'lodash-es';
import { Dict } from '../types';

export function isReferenceFilter(field: string) {
	return field.slice(field.length - 2) === 'Id';
}

export function filterValuesToQuery(obj: any) {
	const result = {} as Dict<string>;
	forEach(obj, (value: string[], key) => {
		if (value.length) {
			result[key] = Array.isArray(value)
				? value
						.map((el: any) => (el.id ? el.id : (el.name ? el.name.replace(/\n/g, '%0A') : el) || el))
						.join(',')
				: value;
		}
	});
	return result;
}
