import { combineReducers } from 'redux';
import { ProfileDetailsReducer } from './profile/reducer';
import { CompetenceDetailsReducer } from './competence/reducer';
import { QuestionDetailsReducer } from './question/reducer';
import { PersonDetailReducer } from './persons/reducer';
import { CompanyDetailsReducer } from './company/reducer';
import { RoleDetailsReducer } from "./role/reducer";

export const DetailsReducer = combineReducers({
	profile: ProfileDetailsReducer,
	competence: CompetenceDetailsReducer,
	company: CompanyDetailsReducer,
	question: QuestionDetailsReducer,
	roles: RoleDetailsReducer,
	person: PersonDetailReducer,
});
