import React, { FC, useCallback, useRef } from 'react';
import styled from 'astroturf';
import { SelectItem, useSelect } from '../../../hooks/useSelect';
import { SelectItemWrapper } from '../../service/Select/SelectItemWrapper';
import { SelectItemContent } from '../../service/Select/SelectItemContent';
import { SelectInlineText } from '../../service/Select/SelectInlineText';
import { SelectItems } from '../../service/Select/SelectItems';
import { SelectMenu } from '../../service/Select/SelectMenu';
import { SelectMenuWrapper } from '../../service/Select/SelectMenuWrapper';

export interface MenuItem {
	label?: string;
	onClick?: () => void;
}

export interface MenuPopupPros {
	className?: string;
	onSelect?: (item: SelectItem) => void;
	items?: MenuItem[];
	onGetItems?: () => MenuItem[] | Promise<MenuItem[]>;
	disablePortal?: boolean;
	sameWidth?: boolean;
}

export const MenuPopup: FC<MenuPopupPros> = ({
	className,
	onSelect,
	items,
	onGetItems,
	disablePortal,
	sameWidth,
	children,
}) => {
	const container = useRef<HTMLDivElement>(null);

	const { internalItems, open, onClose, onClick } = useSelect({
		items,
		onGetItems,
	});

	const onSelectInternal = useCallback(
		(item: MenuItem) => () => {
			item.onClick?.();
			onClose();
		},
		[onSelect],
	);

	return (
		<Wrapper className={className}>
			<DropdownWrapper onClick={onClick} ref={container}>
				{children}
			</DropdownWrapper>
			<StyledSelectMenu
				onClickOutside={onClose}
				processClickOutside={open}
				anchor={container}
				open={open}
				disablePortal={disablePortal}
				limitHeight
				sameWidth={sameWidth}
				placement={'bottom-end'}
			>
				<StyledSelectMenuWrapper>
					<SelectItems>
						{internalItems?.map((item) => (
							<SelectItemWrapper onClick={onSelectInternal(item)}>
								<SelectItemContent>
									<SelectInlineText>{item.label}</SelectInlineText>
								</SelectItemContent>
							</SelectItemWrapper>
						))}
					</SelectItems>
				</StyledSelectMenuWrapper>
			</StyledSelectMenu>
		</Wrapper>
	);
};

const StyledSelectMenu = styled(SelectMenu)`
	max-width: 212px;
	min-width: 192px;
`;

const StyledSelectMenuWrapper = styled(SelectMenuWrapper)`
	height: 100%;
	display: flex;
	flex-direction: column;
`;

const Wrapper = styled.div`
	@import '../../../styles/constants.scss';
	position: relative;
	display: inline-flex;
`;

const DropdownWrapper = styled.div``;
