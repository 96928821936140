import React, { FC, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import { ConfirmationModal } from '../../modals/ConfirmationModal';
import { ClassifierDropdown } from '../../fields/ClassifierDropdown';
import { usePromiseCallback } from '../../../../service/hooks/usePromiseCallback';
import { Reference } from '../../../../service/typings/entities';
import { ClassifierDefaultSelectMenu } from './ClassifierDefaultSelectMenu';

export interface ClassifierDropdownProps {
	loading?: boolean;
	onClick?: () => void;
}

export interface ClassifierSelectMenuProps {
	open: boolean;
	withColors?: boolean;
	items: Reference[];
	anchor: React.RefObject<HTMLElement>;
	onSelect: (id: number) => void;
	onRecolor: (id: number, color: string) => void;
	onRemove: (id: number) => void;
	onCreate: (item: Reference) => Promise<any>;
	onClose: () => void;
	onExited: () => void;
	addable?: boolean;
	removable?: boolean;
	disablePortal?: boolean;
	sameWidth?: boolean;
	addText?: string;
}

interface Props {
	withColors?: boolean;
	onSelect: (value: Reference) => void;
	onAddClassifier?: (data: Reference) => Promise<any>;
	onLoadClassifiers: () => Promise<any>;
	onRemoveClassifier?: (id: number) => Promise<any>;
	onChangeClassifier?: (id: number, data: Partial<Reference>) => void;
	dropdownComponent?: React.ComponentType<ClassifierDropdownProps>;
	dropdownProps?: any;
	selectMenuComponent?: React.ComponentType<ClassifierSelectMenuProps>;
	selectMenuProps?: any;
	selectOnExit?: boolean;
	addable?: boolean;
	removable?: boolean;
	showCount?: boolean;
}

interface RemoveModal extends Reference {
	open: boolean;
}

export const ClassifierAbstractSelect: FC<Props> = ({
	onSelect,
	onAddClassifier,
	onLoadClassifiers,
	onRemoveClassifier,
	onChangeClassifier,
	withColors,
	dropdownComponent: DropdownComponent = ClassifierDropdown,
	dropdownProps,
	selectMenuComponent: SelectMenuComponent = ClassifierDefaultSelectMenu,
	selectMenuProps,
	selectOnExit,
	addable,
	removable,
	showCount,
}) => {
	const cell = useRef<HTMLDivElement>(null);
	const [open, setOpen] = useState(false);
	const [selectedItem, setSelectedItem] = useState<Reference | null>(null);
	const [modalData, setModalData] = useState<RemoveModal>({
		open: false,
	});
	const [renderPopups, setRenderPopups] = useState(false);
	const [references, setReferences] = useState<Reference[]>([]);
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();

	const [debouncedChangeClassifier] = useDebouncedCallback((id: number, data: Partial<Reference>) => {
		if (onChangeClassifier) {
			onChangeClassifier(id, data);
		}
	}, 0);

	const onCloseRemoveModal = useCallback(() => {
		setModalData({
			...modalData,
			open: false,
		});
	}, [modalData, setModalData]);

	const onOpenRemoveModal = useCallback(
		(id) => {
			const reference = references.find((el) => el.id === id);
			if (reference) {
				setModalData({
					open: true,
					id: reference.id,
					name: reference.name,
					count: reference.count,
				});
			}
		},
		[references, setModalData],
	);

	const onSubmitRemoveModal = usePromiseCallback(() => {
		const id = modalData.id;
		if (onRemoveClassifier) {
			return onRemoveClassifier(id as number).then(() => {
				setReferences(references.filter((reference) => reference.id !== id));
			});
		}
	}, [onRemoveClassifier, modalData, references, setReferences]);

	const onExited = useCallback(() => {
		if (selectOnExit && selectedItem) {
			onSelect(selectedItem);
			setSelectedItem(null);
		}
	}, [onSelect, selectedItem, setSelectedItem, selectOnExit]);

	const onClose = useCallback(() => {
		if (!modalData.open) {
			setOpen(false);
		}
	}, [setOpen, modalData]);

	const onOpen = useCallback(() => {
		if (!open) {
			setLoading(true);
			onLoadClassifiers()
				.then((response) => {
					setReferences(response);
					setRenderPopups(true);
					setOpen(true);
				})
				.finally(() => {
					setLoading(false);
				});
		} else {
			setOpen(false);
		}
	}, [open, setOpen, setLoading, setReferences, setRenderPopups, onLoadClassifiers]);

	const onSelectItem = useCallback(
		(id: number) => {
			const reference = references.find((el) => el.id === id);
			if (reference) {
				if (selectOnExit) {
					setSelectedItem(reference);
				} else {
					onSelect(reference);
				}
				setOpen(false);
			}
		},
		[references, onSelect, selectOnExit, setSelectedItem, setOpen],
	);

	const onCreate = usePromiseCallback(
		(data: Reference) => {
			if (onAddClassifier) {
				return onAddClassifier(data).then((response: Reference) => {
					setReferences((references) => [response, ...references]);
				});
			}
		},
		[onAddClassifier, setReferences],
	);

	const onRecolor = useCallback(
		(id: number, color: string) => {
			setReferences(references.map((reference) => (reference.id === id ? { ...reference, color } : reference)));
			debouncedChangeClassifier(id, { color });
		},
		[references, setReferences, debouncedChangeClassifier],
	);

	return (
		<>
			<DropdownComponent ref={cell} onClick={onOpen} loading={loading} open={open} {...dropdownProps} />
			{renderPopups && (
				<SelectMenuComponent
					withColors={withColors}
					showCount={showCount}
					anchor={cell}
					open={open}
					addable={addable}
					removable={removable}
					onCreate={onCreate}
					onSelect={onSelectItem}
					onRemove={onOpenRemoveModal}
					onRecolor={onRecolor}
					onClose={onClose}
					onExited={onExited}
					items={references}
					{...selectMenuProps}
				/>
			)}

			{renderPopups && modalData.name && (
				<ConfirmationModal
					open={modalData.open}
					onClose={onCloseRemoveModal}
					title={t('grid.removeClassifierModal.title')}
					question={t('grid.removeClassifierModal.question', { name: modalData.name })}
					explanation={t('grid.removeClassifierModal.explanation', { count: modalData.count })}
					cancelText={t('grid.removeClassifierModal.cancel')}
					submitText={t('grid.removeClassifierModal.submit')}
					onSubmit={onSubmitRemoveModal}
				/>
			)}
		</>
	);
};
