import styled from 'astroturf';

export const SelectItems = styled.div`
	@import '../../../styles/mixins.scss';
	@include thin-scrollbar(#d8d8d8);
	max-height: 320px;
	overflow: auto;
	width: 100%;
	height: 100%;
	margin-top: 10px;
	margin-bottom: 10px;
`;
