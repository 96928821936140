import React, { FC } from 'react';
import styled from 'astroturf';
import { Ripple } from '@ea/admin/src/components/general/elements/service/Ripple';

interface Props {
	className?: string;
	onClick?: () => void;
}

export const SelectItemWrapper: FC<Props> = ({ children, onClick, className }) => {
	return (
		<Wrapper className={className}>
			<Ripple onClick={onClick}>{children}</Ripple>
		</Wrapper>
	);
};

const Wrapper = styled.label`
	@import '../../../styles/constants.scss';
	height: 36px;
`;
