import styled from 'astroturf';

export const SelectItemContent = styled.label`
	@import '../../../styles/constants.scss';
	text-align: left;
	font-size: 12px;
	display: flex;
	align-items: center;
	width: 100%;
	padding: 10px 11px 10px;
	cursor: pointer;

	&:hover {
		background-color: rgba($color-gray, 0.5);
	}
`;
