import { useCallback, useRef, useState } from 'react';
import { isObject } from 'lodash-es';
import { Dict } from '../service/typings/basic';

export type SelectItem = string | number | Dict;

function getDefaultKey(item: Dict) {
	return item.id || item.key;
}

function getDefaultLabel(item: Dict) {
	return item.name || item.label;
}

interface Options {
	getKey?: (item: SelectItem) => string | number;
	getLabel?: (item: SelectItem) => string;
	onGetItems?: () => SelectItem[] | Promise<SelectItem[]>;
	beforeOpen?: () => void;
	items?: SelectItem[];
	disabled?: boolean;
}

export function useSelect({ getKey, getLabel, onGetItems, items, disabled, beforeOpen }: Options) {
	const preventOpen = useRef(false);

	const [open, setOpen] = useState(false);

	const [loading, setLoading] = useState(false);

	const [internalItems, setInternalItems] = useState<any[]>([]);

	const retrieveKey = useCallback(
		(item: SelectItem, index?: number) => {
			if (isObject(item)) {
				return getKey ? getKey(item) : getDefaultKey(item);
			} else {
				return index;
			}
		},
		[getKey],
	);

	const retrieveLabel = useCallback(
		(item: SelectItem) => {
			if (isObject(item)) {
				return getLabel ? getLabel(item) : getDefaultLabel(item);
			} else {
				return item;
			}
		},
		[getLabel],
	);

	const onOpen = useCallback(() => {
		beforeOpen?.();
		preventOpen.current = false;
		if (!disabled) {
			if (items) {
				setInternalItems(items);
				setOpen(true);
			} else {
				if (onGetItems && !loading) {
					setLoading(true);
					Promise.resolve(onGetItems())
						.then((data) => {
							if (!preventOpen.current) {
								setInternalItems(data);
								setOpen(true);
							}
						})
						.finally(() => {
							setLoading(false);
						});
				}
			}
		}
	}, [setOpen, onGetItems, items, disabled, loading]);

	const onClose = useCallback(() => {
		setLoading(false);
		preventOpen.current = true;
		setOpen(false);
	}, [setOpen, setLoading]);

	const onClick = useCallback(() => {
		if (open) {
			onClose();
		} else {
			onOpen();
		}
	}, [onOpen, onClose, open]);

	return { internalItems, setInternalItems, retrieveKey, retrieveLabel, open, loading, onClose, onOpen, onClick };
}
