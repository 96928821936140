import React, { FC } from 'react';
import styled from 'astroturf';
import { PopperPlacementType } from '@material-ui/core';
import { SelectMenu } from '../../../service/Select/SelectMenu';
import { SelectMenuWrapper } from '../../../service/Select/SelectMenuWrapper';
import { ClassifierSelectMenuProps } from './ClassifierAbstractSelect';
import { ClassifierSelectMenuContent } from './ClassifierSelectMenuContent';

interface IProps extends ClassifierSelectMenuProps {
	placement: PopperPlacementType;
	preventResize?: boolean;
	showCount?: boolean;
}

export const ClassifierDefaultSelectMenu: FC<IProps> = ({
	addText,
	anchor,
	onCreate,
	onRecolor,
	onRemove,
	onClose,
	onSelect,
	items,
	withColors,
	open,
	disablePortal,
	sameWidth = true,
	placement,
	showCount,
	preventResize,
	removable,
	addable,
}) => {
	return (
		<SelectMenu
			open={open}
			anchor={anchor}
			onClickOutside={onClose}
			sameWidth={sameWidth}
			disablePortal={disablePortal}
			placement={placement}
			preventResize={preventResize}
		>
			<StyledSelectMenuWrapper>
				<Wrapper>
					<StyledClassifierSelectMenuContent
						addText={addText}
						withColors={withColors}
						onCreate={onCreate}
						onSelect={onSelect}
						onRecolor={onRecolor}
						onRemove={onRemove}
						removable={removable}
						showCount={showCount}
						addable={addable}
						items={items}
					/>
				</Wrapper>
			</StyledSelectMenuWrapper>
		</SelectMenu>
	);
};

const StyledSelectMenuWrapper = styled(SelectMenuWrapper)`
	margin-top: 0;
`;

const Wrapper = styled.div`
	padding: 10px 12px;
	padding-top: 7px;
	box-sizing: content-box;
`;

const StyledClassifierSelectMenuContent = styled(ClassifierSelectMenuContent)`
	width: 100%;
	min-width: 0;
`;
