import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { ContentLoading } from '@ea/common';
import { transformToObject } from '../../../core/services/utils';
import { AccessActions } from './actions';
import { AccessInfoResponse, EntityAccess } from './typings';

export interface AccessEntityState {
	access: number;
	functions: Partial<{
		[key: number]: {
			access: number;
			id: number;
			name: string;
		};
	}>;
}

export interface ICompanyAccess {
	id: number;
	name: string;

	competences: AccessEntityState;
	persons: AccessEntityState;
	profiles: AccessEntityState;
	questions: AccessEntityState;
	roles: AccessEntityState;
}

export interface AccessCompanies {
	companies: Partial<Record<number, ICompanyAccess>>;
}

export interface AccessState {
	info: ContentLoading<AccessCompanies>;
}

const initialState: AccessState = {
	info: {
		content: { companies: {} },
		error: undefined,
		loading: {
			status: 'init',
		},
	},
};

export const AccessReducer = reducerWithInitialState(initialState)
	.case(AccessActions.getAccessInfo.started, (state) => ({
		...state,
		info: {
			...state.info,
			loading: {
				status: 'loading',
			},
		},
	}))
	.case(AccessActions.getAccessInfo.done, (state, { result, params }) => ({
		...state,
		info: {
			...state.info,
			content: normalizeAccessResponse(result, params.isSuperAdmin),
			loading: {
				status: 'loaded',
				type: 'full',
			},
		},
	}))
	.case(AccessActions.getAccessInfo.failed, (state, { error }) => ({
		...state,
		info: {
			...state.info,
			loading: {
				status: 'loaded',
			},
			error,
		},
	}));

const normalizeFunctions = (entity: EntityAccess['functions'], isSuperAdmin: boolean) => {
	return transformToObject(entity, (data) => ({
		key: data.id,
		value: { ...data, access: isSuperAdmin ? 3 : data.access },
	}));
};

const normalizeAccessResponse = (result: AccessInfoResponse, isSuperAdmin: boolean) => {
	return result.companies.reduce<AccessCompanies>(
		(acc, company) => {
			acc.companies[company.id] = {
				id: company.id,
				name: company.name,
				questions: {
					access: isSuperAdmin ? 3 : company.questions.access,
					functions: normalizeFunctions(company.questions.functions, isSuperAdmin),
				},
				competences: {
					access: isSuperAdmin ? 3 : company.competences.access,
					functions: normalizeFunctions(company.competences.functions, isSuperAdmin),
				},
				persons: {
					access: isSuperAdmin ? 3 : company.persons.access,
					functions: normalizeFunctions(company.persons.functions, isSuperAdmin),
				},
				profiles: {
					access: isSuperAdmin ? 3 : company.profiles.access,
					functions: normalizeFunctions(company.profiles.functions, isSuperAdmin),
				},
			} as any;

			return acc;
		},
		{ companies: {} },
	);
};
