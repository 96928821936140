import React, { CSSProperties, FC, MouseEvent, ReactNode } from 'react';
import styled from 'astroturf';
import { Button, withStyles } from '@material-ui/core';
import { colors, fonts, MaterialButtonProps } from '../../../service/material-ui';

interface IProps {
	className?: string;
	disabled?: boolean;
	onClick?: (e: MouseEvent) => void;
	type?: 'button' | 'reset' | 'submit';
	style?: CSSProperties;
	icon?: ReactNode;
}

export const TextButton: FC<IProps> = ({ onClick, disabled = false, className = '', type, style, icon, children }) => {
	return (
		<StyledButton className={className} disabled={disabled} onClick={onClick} type={type} style={style}>
			{Boolean(icon) && <IconWrapper needMargin={!!children}>{icon}</IconWrapper>}
			{children}
		</StyledButton>
	);
};

const IconWrapper = styled.span<{ needMargin?: boolean; disabled?: boolean }>`
	display: inline-flex;

	&.needMargin {
		margin-right: 8px;
	}
`;

const StyledButton = withStyles({
	root: {
		letterSpacing: 'normal',
		fontFamily: fonts.lato,
		fontWeight: 'bold',
		boxSizing: 'border-box',
		cursor: 'pointer',
		textDecoration: 'none',
		flex: '0 0 auto',
		boxShadow: 'none',
		borderRadius: '4px',
		textAlign: 'center',
		display: 'flex',
		alignItems: 'center',
		padding: '6px 7px 6px 6px',
		minWidth: '0',
		textTransform: 'none',
		fontSize: '12px',
		lineHeight: '12px',
		color: colors.blue,
		fill: colors.blue,
		transition: 'background 0.5s, fill 0.5s, color 0.5s',
		transitionDelay: 'fill 0.5s, color 0.5s',
		'&:disabled': {
			color: colors.disabled,
			fill: colors.disabled,
			cursor: 'not-allowed',
		},
	},
})(({ classes = {}, className, ...other }: MaterialButtonProps) => (
	<Button {...other} className={`${classes.root} ${className}`} />
));
