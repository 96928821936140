import React, { FC } from 'react';
import styled from 'astroturf';
import { Link, LinkProps } from 'react-router-dom';
import { ReactComponent as LinkIcon } from '../../images/icons/actions/link.svg';
import { TextButton } from '../elements/buttons/TextButton';

export const LinkWithIcon: FC<LinkProps> = ({ children, ...props }) => {
	return (
		<Wrapper {...props}>
			<StyledTextButton>
				{children} <StyledLinkIcon />
			</StyledTextButton>
		</Wrapper>
	);
};
const Wrapper = styled(Link)`
	text-decoration: none;
	display: inline-flex;
`;

const StyledTextButton = styled(TextButton)`
	font-size: 12px;
	font-weight: 600;
`;

const StyledLinkIcon = styled(LinkIcon)`
	margin-left: 8px;
`;
