import styled from 'astroturf';
import { useTranslation } from 'react-i18next';
import React, { ChangeEvent, FC, useCallback, useState } from 'react';
import { ColorPicker } from '../../fields/ColorPicker/ColorPicker';
import { ReactComponent as ChoiceColor } from '../../../../images/icons/choice-color.svg';
import { useOnEnter } from '../../../../service/hooks/useOnEnter';
import { Reference } from '../../../../service/typings/entities';
import { Color, Item } from './ClassifierSelectItem';

interface Props {
	items: Reference[];
	onSelect?: (id: number) => void;
	onRemove?: (id: number) => void;
	onRecolor?: (id: number, color: string) => void;
	onCreate: (value: Reference) => Promise<any>;
	withColors?: boolean;
	removable?: boolean;
	showCount?: boolean;
	addable?: boolean;
	className?: string;
	addText?: string;
}

interface IColorSelection {
	color?: string;
	target?: Reference;
	editing?: boolean;
}

export const ClassifierSelectMenuContent: FC<Props> = ({
	addText,
	items,
	withColors,
	onRemove,
	onSelect,
	onCreate,
	onRecolor,
	className,
	showCount,
	removable,
	addable,
}) => {
	const { t } = useTranslation();
	const [value, setValue] = useState('');
	const [color, setColor] = useState<string>();
	const [adding, setAdding] = useState(false);
	const [colorSelection, setColorSelection] = useState<IColorSelection>({});

	const onChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			setValue(event.target.value);
		},
		[setValue],
	);

	const onEnter = useOnEnter(() => {
		if (value) {
			onCreate({ name: value, color })
				.then(() => {
					setValue('');
					setColor(undefined);
					if (!colorSelection.target) {
						setColorSelection({
							...colorSelection,
							editing: false,
						});
					}
				})
				.finally(() => {
					setAdding(false);
				});
		}
	}, [onCreate, value, color, setAdding, setColor, colorSelection, setColorSelection]);

	const onStartChangeColor = useCallback(
		(color: string, target?: Reference) => {
			setColorSelection({
				editing: true,
				color,
				target,
			});
		},
		[setColorSelection],
	);

	const onChangeColor = useCallback(
		(hex: string) => {
			if (colorSelection.target) {
				if (onRecolor && colorSelection.target.id) {
					onRecolor(colorSelection.target.id, hex);
				}
			} else {
				setColor(hex);
			}
			setColorSelection({
				...colorSelection,
				color: hex,
			});
		},
		[onRecolor, setColor, colorSelection, setColorSelection],
	);

	const onCloseColorPicker = useCallback(() => {
		setColorSelection({
			editing: false,
		});
	}, [setColorSelection]);

	return (
		<Wrapper>
			<Body className={className}>
				{addable && (
					<InputWrapper>
						{withColors && (
							<Color
								style={{ backgroundColor: color }}
								onClick={() => onStartChangeColor(color || '#FFF')}
							>
								{!color ? <ChoiceColor /> : null}
							</Color>
						)}
						<StyledInput
							value={value}
							disabled={adding}
							onChange={onChange}
							onKeyPress={onEnter}
							placeholder={addText || t('common.actions.addSomething')}
						/>
					</InputWrapper>
				)}

				{items.length > 0 && (
					<Items>
						{items.map((item, index) => (
							<Item
								id={item.id}
								key={item.id}
								onSelect={onSelect}
								onRemove={onRemove}
								onChangeColor={onStartChangeColor}
								withColor={withColors}
								showCount={showCount}
								color={item.color}
								count={item.count}
								name={item.name}
								last={index === items.length - 1}
								removable={removable}
							/>
						))}
					</Items>
				)}
			</Body>
			{colorSelection.editing && (
				<ColorPickerWrapper>
					<ColorPicker
						color={colorSelection.color || '#FFFFFF'}
						onChange={onChangeColor}
						onClose={onCloseColorPicker}
						debounce={true}
					/>
				</ColorPickerWrapper>
			)}
		</Wrapper>
	);
};

const ColorPickerWrapper = styled.div`
	width: 180px;
	min-height: 180px;
	flex-shrink: 0;
	position: relative;
	margin-left: 16px;
`;
const Body = styled.div`
	min-width: 218px;
	max-width: 500px;
`;
const InputWrapper = styled.div`
	display: flex;
	margin-bottom: 9px;
`;

const StyledInput = styled.input`
	border: solid 1px #cacaca;
	background: none;
	font-size: 12px;
	line-height: 32px;
	height: 32px;
	padding: 0 7px;
	outline: none;
	display: block;
	width: 100%;
	box-sizing: border-box;
	&::placeholder {
		opacity: 0.4;
		color: #212121;
	}
`;
const Wrapper = styled.div`
	display: flex;
`;

const Items = styled.div`
	@import '../../../../styles/mixins.scss';
	@include thin-scrollbar();
	overflow: auto;
	max-height: 180px;
`;
